"use strict";

/**
 * @ngdoc function
 * @name informaApp.controller:FaqSvc
 * @description
 * # FaqSvc
 * Service of the informaApp
 */

angular.module("informaApp")
    .service("FaqSvc", ["$cookies", "BaseApiService", "$rootScope", "ConstantsSvc", "FileDownloader", function($cookies, BaseApiService, $rootScope, ConstantsSvc, FileDownloader) {
        return {
            getAllQuestion: function() {
                return BaseApiService.get('faq/all');
            },
            addQuestion: function(question) {
                return BaseApiService.post('faq/add', question);
               
            },
            updateQuestion: function(question) {
                return BaseApiService.post('faq/edit', question);

            },
            deleteQuestion: function(id) {
                return BaseApiService.delete('faq/delete?id=' + id);
            },
            downloadMethodology: function () {
                FileDownloader.download(
                    BaseApiService.post('faq/tutorial')

                );
            },
            downloadAttachment: function (id) {
                FileDownloader.download(
                    BaseApiService.post('faq/download', {id: id})
                );
            }
        }
    }]);